var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h5 mb-sm-4 pa-4 pa-sm-0 primary--text"},[_c('span',[_vm._v(" Редактирование роли "+_vm._s(_vm.schema.title)+" ")])]),_c('v-card',{staticClass:"elevation-4"},[_c('v-card-text',{staticClass:"role-container"},[_c('v-card',[_c('v-list',[_vm._l((_vm.schema.groups),function(groupContent,groupIndex){return _c('v-list-item',{key:groupIndex,attrs:{"disabled":groupContent.title === _vm.currentTab},on:{"click":function($event){return _vm.changeTab(groupContent.title)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(groupContent.title)}})],1)],1)}),_c('v-divider')],2)],1),_vm._l((_vm.schema.groups),function(groupContent,groupIndex){return [(
            !_vm.checkCurrentTab(groupContent.title) &&
              groupContent.title === _vm.currentTab
          )?_c('div',{key:groupIndex},[_c('div',[_c('v-expansion-panels',{attrs:{"accordion":""}},_vm._l((groupContent.modules),function(moduleContent,moduleIndex){return _c('v-expansion-panel',{key:moduleIndex},[_c('v-expansion-panel-header',[_vm._v(_vm._s(moduleContent.title)+" ")]),_c('v-expansion-panel-content',{attrs:{"color":"head"}},[_c('div',{staticClass:"grid-table",class:{
                      'grid-table-xs': _vm.$vuetify.breakpoint.mdAndDown
                    }},[_vm._l((groupContent.actions),function(actionContent,actionIndex){return [(moduleContent.actions[actionIndex])?_c('v-card',{key:actionIndex,staticClass:"text-center mt-3"},[_c('v-card-title',{attrs:{"classё":"body-1 head lighten-2"}},[_vm._v(_vm._s(actionContent)+" ")]),_c('v-card-text',[(
                              moduleContent.actions[actionIndex].levels
                                .length > 1
                            )?_c('v-select',{staticClass:"mt-4",attrs:{"items":moduleContent.actions[actionIndex].levels,"clearable":"","item-text":"text","item-value":"value","label":"Select level"},model:{value:(
                              _vm.dataObject[groupIndex]['modules'][moduleIndex]
                                .actions[
                                moduleContent.actions[actionIndex].name
                              ].levels
                            ),callback:function ($$v) {_vm.$set(_vm.dataObject[groupIndex]['modules'][moduleIndex]
                                .actions[
                                moduleContent.actions[actionIndex].name
                              ], "levels", $$v)},expression:"\n                              dataObject[groupIndex]['modules'][moduleIndex]\n                                .actions[\n                                moduleContent.actions[actionIndex].name\n                              ].levels\n                            "}}):_c('v-checkbox',{staticClass:"justify-center",attrs:{"label":'',"value":moduleContent.actions[actionIndex].levels[0]
                                .value},model:{value:(
                              _vm.dataObject[groupIndex]['modules'][moduleIndex]
                                .actions[
                                moduleContent.actions[actionIndex].name
                              ].levels
                            ),callback:function ($$v) {_vm.$set(_vm.dataObject[groupIndex]['modules'][moduleIndex]
                                .actions[
                                moduleContent.actions[actionIndex].name
                              ], "levels", $$v)},expression:"\n                              dataObject[groupIndex]['modules'][moduleIndex]\n                                .actions[\n                                moduleContent.actions[actionIndex].name\n                              ].levels\n                            "}}),(moduleContent.actions[actionIndex].fields)?_c('v-select',{attrs:{"items":moduleContent.actions[actionIndex].fields,"item-text":"text","item-value":"value","label":"Select fields","multiple":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{staticClass:"cardColor sticky-select-all",attrs:{"ripple":""},on:{"click":function($event){return _vm.toggle(
                                    groupIndex,
                                    moduleIndex,
                                    moduleContent,
                                    actionIndex
                                  )}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.icon( groupIndex, moduleIndex, moduleContent, actionIndex ))+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Выбрать всё ")])],1)],1),_c('v-divider',{staticClass:"mt-2 "})]},proxy:true},{key:"selection",fn:function(ref){
                                  var item = ref.item;
                                  var index = ref.index;
return [(index === 0)?_c('v-chip',{staticClass:"chip_content"},[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.dataObject[groupIndex]["modules"][ moduleIndex ].actions[ moduleContent.actions[actionIndex].name ].fields.length - 1)+")")]):_vm._e()]}}],null,true),model:{value:(
                              _vm.dataObject[groupIndex]['modules'][moduleIndex]
                                .actions[
                                moduleContent.actions[actionIndex].name
                              ].fields
                            ),callback:function ($$v) {_vm.$set(_vm.dataObject[groupIndex]['modules'][moduleIndex]
                                .actions[
                                moduleContent.actions[actionIndex].name
                              ], "fields", $$v)},expression:"\n                              dataObject[groupIndex]['modules'][moduleIndex]\n                                .actions[\n                                moduleContent.actions[actionIndex].name\n                              ].fields\n                            "}}):_vm._e()],1)],1):_vm._e()]})],2),(moduleContent.rules)?_c('div',{staticClass:"d-flex"},_vm._l((moduleContent.rules),function(ruleContent,ruleIndex){return _c('v-checkbox',{key:ruleIndex,staticClass:"mb-3 mr-5 justify-center",attrs:{"label":ruleContent.title,"value":ruleContent.levels[0].value},model:{value:(
                        _vm.dataObject[groupIndex]['modules'][moduleIndex][
                          'rules'
                        ][ruleContent.name].levels
                      ),callback:function ($$v) {_vm.$set(_vm.dataObject[groupIndex]['modules'][moduleIndex][
                          'rules'
                        ][ruleContent.name], "levels", $$v)},expression:"\n                        dataObject[groupIndex]['modules'][moduleIndex][\n                          'rules'\n                        ][ruleContent.name].levels\n                      "}})}),1):_vm._e()])],1)}),1),_c('v-card',{staticClass:"head lighten-3"},[_c('v-card-text',{staticClass:"d-flex"},_vm._l((groupContent.rules),function(ruleContent,ruleIndex){return _c('v-checkbox',{key:ruleIndex,staticClass:"mr-5 mb-3",attrs:{"label":ruleContent.title,"value":ruleContent.levels[0].value,"hide-details":""},model:{value:(
                    _vm.dataObject[groupIndex].rules[ruleContent.name].levels
                  ),callback:function ($$v) {_vm.$set(_vm.dataObject[groupIndex].rules[ruleContent.name], "levels", $$v)},expression:"\n                    dataObject[groupIndex].rules[ruleContent.name].levels\n                  "}})}),1)],1)],1)]):_vm._e()]})],2),_c('v-card-text',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.reset()}}},[_vm._v(" Сбросить ")]),_c('v-spacer'),_c('v-btn',{attrs:{"link":"","text":"","to":"/roles"}},[_vm._v(" Отмена ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }